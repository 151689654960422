<style scoped>
    .hover-over {
        display: none;
    }
    .comment:hover .hover-over {
        display: block;
    }
</style>
<template>
    <div>
        <div class="comment">
            <small class="grid border-bottom column-gap-1 d-inline-flex">
                <span class="fw-bold text-muted">{{ propRow.CreatedBy }}</span>
                <span class="text-muted">{{ utils.formatDate(propRow.Created, "General Date Short Time") }}</span>
            </small>
            <small class="hover-over float-end">
                <div class="btn btn-link btn-sm p-0" @click="props.dataObject.delete(propRow.index)">{{ $t("Delete") }}</div>
                <div v-if="propRow.Completed" class="btn btn-link btn-sm p-0" @click="closeOutComment.reset(); closeOutComment.editable = !closeOutComment.editable;">{{ $t("Edit action") }}</div>
                <div v-else class="btn btn-link btn-sm p-0" @click="">{{ $t("Assign") }}</div>
                <div class="btn btn-link btn-sm p-0" @click="comment.reset(); comment.editable = !comment.editable">{{ $t("Edit") }}</div>
                <div class="btn btn-link btn-sm p-0" @click="replyComment.editable = !replyComment.editable">{{ $t("Reply") }}</div>
            </small>
            <div>
                <div v-if="!comment.editable">{{ comment.comment }}<span v-if="propRow.AssignedTo" class="text-info">&nbsp;{{ $t("Assigned to: ") + propRow.AssignedTo }}</span></div>
                <input v-else v-model="comment.comment" class="w-100 form-control form-control-sm m-0" type="text">
                <button v-if="comment.editable" @click="comment.save()" class="btn btn-sm btn-primary p-1 py-0">{{ $t("Save") }}</button>
                <button v-if="comment.editable" @click="comment.reset()" class="ms-1 btn btn-sm btn-secondary p-1 py-0">{{ $t("Cancel") }}</button>
                <div v-if="propRow.AssignedTo_ID" :class="{ 'ms-4': propRow.Completed }">
                    <small v-if="propRow.Completed" class="grid border-bottom column-gap-1 d-inline-flex">
                        <span>{{ $t("Completed by: ") }}</span>
                        <span class="fw-bold">{{ propRow.CompletedBy }}</span>
                        <span class="text-muted">{{ utils.formatDate(propRow.Completed, "General Date Short Time") }}</span>
                    </small>
                    <div v-if="propRow.CloseOutComment && !closeOutComment.editable">{{ closeOutComment.comment }}</div>
                    <input v-else v-model="closeOutComment.comment" class="w-100 form-control form-control-sm m-0" type="text">
                    <button v-if="closeOutComment.comment !== propRow.CloseOutComment" @click="closeOutComment.save()" class="btn btn-sm btn-primary p-1 py-0">{{ $t("Save") }}</button>
                    <button v-if="closeOutComment.comment !== propRow.CloseOutComment" @click="closeOutComment.reset()" class="ms-1 btn btn-sm btn-secondary p-1 py-0">{{ $t("Cancel") }}</button>
                </div>
            </div>
            <div v-if="replyComment.editable">
                <div class="form-group">
                    <label>{{ $t("Reply") }}</label>
                    <input v-model="replyComment.comment" class="w-100 form-control form-control-sm m-0" type="text">
                </div>
                <button @click="replyComment.createComment(true)" class="btn btn-sm btn-primary p-1 py-0">{{ $t("Save") }}</button>
                <button @click="replyComment.editable = false; replyComment.comment = null;" class="ms-1 btn btn-sm btn-secondary p-1 py-0">{{ $t("Cancel") }}</button>
            </div>
        </div>
        <div v-for="row in props.dataObject.data.filter(x => x.ParentComment_ID === propRow.ID)" class="ms-4">
            <Comment :data-object="props.dataObject" :createNew="props.createNew" :index="row.index"></Comment>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { Ref, ref } from 'vue';
    import DataObject from 'o365.vue.ts';
    import utils from "o365.modules.utils.js"
    import { getUserSession } from "o365.modules.configs.ts";

    const props = defineProps<{
        dataObject: DataObject
        index?: number
        createNew?: object
    }>();

    class Box {
        public editable: boolean = false;
        public comment: string = null;

        async createComment(isReply: boolean = false) {
            props.createNew["Comment"] = this.comment;
            if (isReply) {
                props.createNew["ParentComment_ID"] = propRow.value.ID;
            }

            props.dataObject.createNew(props.createNew);
            this.editable = false;
        }
    }

    const propRow:Ref = ref(null)
    const comment:Ref = ref(null);
    const closeOutComment:Ref = ref(null);
    const replyComment:Ref = ref(null);

    const session = getUserSession();

    if (typeof props.index === 'number') {
        propRow.value = props.dataObject.data[props.index]

        class Comment extends Box {
            private commentColumn: string;

            constructor(commentColumn: string) {
                super();
                this.commentColumn = commentColumn;
                this.comment = propRow.value[commentColumn];
                this.editable = this.comment === null;
            }

            async save() {
                if (propRow.value[this.commentColumn] !== this.comment) {
                    propRow.value[this.commentColumn] = this.comment;
                    propRow.value.CompletedBy_ID = session.personId;
                    propRow.value.Completed = new Date();
                    await props.dataObject.save();
                }
                this.editable = false;
            }

            reset() {
                this.comment = props.dataObject.data[props.index][this.commentColumn];
                this.editable = false;
            }
        }

        comment.value = new Comment("Comment");
        closeOutComment.value = new Comment("CloseOutComment");
        replyComment.value = new Box();

        props.dataObject.on("AfterSave", () => {
            console.log("index: " + props.index + ". comment: " + propRow.value.Comment + ". closeoutcomment: " + propRow.value.CloseOutComment);
            comment.value.reset();
            closeOutComment.value.reset();
        })
    }
</script>